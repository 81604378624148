
@import "../../bootstrap/variables.less";

@body-bg:					#fff;

@text-color:				#000;
@link-color:				#fb0000;
@link-hover-color:			#000;
@link-hover-decoration:		underline;

@font-family-base:			Verdana, Arial, Helvetica, sans-serif;
@font-size-base:			10px;
@line-height-base:			1;

@font-size-h1:				56px;
@headings-small-color:		#000;

@thumb-width:				490px;
@thumb-height:				368px;
@thumb-margin-top:			0px;
@thumb-margin-right:		1px;
@thumb-margin-bottom:		0px;
@thumb-margin-left:			1px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #000;
@thumb-hover-border:		@thumb-border-width solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				18px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				18px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		underline;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	0;
@sites-footer-margin:				10px;
@sites-footer-size:					12px;
@sites-footer-color:				#fff;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	text-align: center;
	margin: 0 auto 20px;
	padding: 2px;
	max-width: 920px;
	font-weight: bold;
	overflow: hidden;
	.text-nowrap {
		float: left;
	}
	small {
		font-size: 18px;
		line-height: 20px;
		display: block;
		overflow: hidden;
		text-align: left;
		padding-left: 30px;
		padding-top: 15px;
	}
}
#language-switcher {
	position: absolute;
	right: 10px;
	top: 0;
}
.mozaique {
	clear: both;
}
.thumb-simple {
	border-bottom: 1px solid #fb0000;
	height: @thumb-height + @thumb-margin-top + @thumb-margin-bottom + (2 * @thumb-border-width) + 1;
}
@media(max-width: 768px) {
	h1 {
		.text-nowrap {
			float: none;
		}
		small {
			padding: 5px;
		}
	}
}